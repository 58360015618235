import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "../../../UI_Components/Tabs";
import SubHeader from "../../../UI_Components/SubHeader";
import Inprocess from "../../../Components/OnboardingStatus/Inprocess";
import Completed from "../../../Components/OnboardingStatus/Completed";
import Pending from "../../../Components/OnboardingStatus/Pending";
import apiManager from "networking/baseAPIManager";
import { CoreLoader } from "common/components";
import isResourceAvailable from "utils/permit_util";
import "../../../assets/style/Responsive.css";
import { SearchFilter } from "Components/AttendaceReport";
const EmployeeOnboarding = () => {
  const [employeeOnboardingData, setEmployeeOnboardingData] = useState([]);
  const [employeeSearch, setEmployeeSearch] = useState([])
  const [loading, setLoading] = useState(false);
  const [debounceVal, setDebounceVal] = useState('');
  const [searchString, setSearchString] = useState("");
  const showStartOnboarding = isResourceAvailable("onboarding", "create");
  const employeesList = () => {
    setLoading(true);
    apiManager
      .employeeList()
      .then((response) => {
        setLoading(false);
        setEmployeeOnboardingData(response.data);
        setEmployeeSearch(response.data)
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    employeesList();
  }, []);

  let trueStatusData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "completed");
  let approvedData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "approved");
  let pendingStatusData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "pending");

  let arrData = [
    { title: "Pending", content: <Pending data={pendingStatusData} /> },
    { title: "In Process", content: <Inprocess data={approvedData} /> },
    { title: "Completed", content: <Completed data={trueStatusData} /> },
  ];

  const [tabs, setTabs] = useState(arrData);

  console.log(employeeOnboardingData, 'employeeOnboardingDatafg');
  useEffect(() => {
    setTabs([...arrData]);
  }, [employeeOnboardingData]);

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (searchString) {

  //     const result = employeeOnboardingData.filter(
  //       (rowObj) =>
  //         rowObj.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
  //         rowObj.firstname.toLowerCase().includes(searchString.toLowerCase()) ||
  //         rowObj.middlename?.toLowerCase().includes(searchString.toLowerCase()) ||
  //         rowObj.lastname?.toLowerCase().includes(searchString.toLowerCase())
  //     );


  //     const filteredPending = result.filter((data) => String(data.status).toLowerCase() === "pending");
  //     const filteredInProcess = result.filter((data) => String(data.status).toLowerCase() === "approved");
  //     const filteredCompleted = result.filter((data) => String(data.status).toLowerCase() === "completed");

  //     setTabs([
  //       { title: "Pending", content: <Pending data={filteredPending} /> },
  //       { title: "In Process", content: <Inprocess data={filteredInProcess} /> },
  //       { title: "Completed", content: <Completed data={filteredCompleted} /> },
  //     ]);
  //   } else {
  //     setTabs(arrData);
  //   }
  // }, [searchString]);
  function debounce(cb, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const debouncedChange = debounce((inputValue) => {
    setDebounceVal(inputValue);
  }, 1000);

  useEffect(() => {
      debouncedChange(searchString);
  }, [searchString])

  useEffect(() => {
    if (debounceVal) {
      setLoading(true);
      apiManager
        .employeeSearchList(debounceVal)
        .then((response) => {
          setLoading(false);
          setEmployeeOnboardingData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setEmployeeOnboardingData(employeeSearch); 
    }
  }, [debounceVal, employeeSearch]);
  return (
    <>
      <div className="container-fluid">


        <div className="AttendanceReporttop">
          <SubHeader location={useLocation()} />
          <SearchFilter
            data={searchString}
            setSearchString={setSearchString}
            placeholder="Search Employee By Name/ID"
          />
        </div>
        <div className="tab-container">
          <div className="start_onboarding">
            <button
              className="button"
              onClick={() => navigate("/hrtools/employee-onboarding/candidatedetails")}>
              Start Onboarding
            </button>

          </div>
          <Tabs tabs={tabs} />
        </div>
      </div>
      <CoreLoader show={loading} />
    </>
  );
};

export default EmployeeOnboarding;
