import React, { useEffect, useState } from "react";
import { SubHeader } from "UI_Components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import userImage from "../../../assets/images/UserDefultImage.png";
import { IoMailOutline, IoDownloadSharp } from "react-icons/io5";
import { PiBagSimple } from "react-icons/pi";
import apiManager from "networking/baseAPIManager";

const EmployeeProfiles = ({ setOpen, open }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [candidateData, setCandidateData] = useState({});
  const [loader, setLoader] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;


  useEffect(() => {
    fetchPersonalInfo();
  }, []);

  const fetchPersonalInfo = () => {
    setLoader(true);
    apiManager
      .PersonalInformation()
      .then((res) => {
        setCandidateData(res?.data || {});
      })
      .catch((err) => {
        console.error("Error fetching personal information:", err);
      })
      .finally(() => {
        setLoader(false);
      });
  };
console.log(candidateData,'candidateDatakvbn')
  return (
    <div className="container-fluid">
      <SubHeader location={location} />
      <div className="mt-4 mb-4">
        <div className="card-title">Employee Details</div>
      </div>

      <div className="row Emp-Det-Row">
        {/* Left Column */}
        <div className="col-4">
          <div className="Emp-Det-left">
            <div className="card">
              <div className="Emp-top-info clearfix">
                <div className="Emp-profile-img">
                  <img
                    src={
                      candidateData?.image
                        ? `${BASE_URL}/${candidateData.image}`
                        : userImage
                    }
                    alt="Employee"
                  />
                </div>
                <div className="Emp-profile-text">
                  <h5>{candidateData.FullName || "N/A"}</h5>
                  <p>
                    <PiBagSimple /> {candidateData?.info?.designation || "N/A"}
                  </p>
                  <p className="m-0">
                    <IoMailOutline /> {candidateData?.info?.email || "N/A"}
                  </p>
                </div>
              </div>
            </div>

            {/* Personal Details */}
            <div className="card mt-3">
              <div className="card-title">Personal Details</div>
              <div className="Emp-Det-List">
                <ul>
                  <li>
                    <span>Documental DOB</span>
                    <p>
                      {candidateData?.info?.documentDob
                        ? moment(candidateData?.info?.documentDob).format("DD MMM YYYY")
                        : "N/A"}
                    </p>
                  </li>
                  <li>
                    <span>Gender</span>
                    <p>{candidateData?.info?.gender || "N/A"}</p>
                  </li>
                  <li>
                    <span>Mobile No.</span>
                    <p>{candidateData?.info?.mobile_number || "N/A"}</p>
                  </li>
                  <li>
                    <span>Emergency Contact No.</span>
                    <p>{candidateData?.info?.emergency_number || "N/A"}</p>
                  </li>
                </ul>
              </div>
            </div>

            {/* Professional Details */}
            <div className="card mt-3">
              <div className="card-title">Professional Details</div>
              <div className="Emp-Det-List">
                <ul>
                  <li>
                    <span>Employee ID</span>
                    <p>{candidateData?.info?.employeeID || "N/A"}</p>
                  </li>
                  <li>
                    <span>Company Email</span>
                    <p>{candidateData?.info?.company_email || "N/A"}</p>
                  </li>
                  <li>
                    <span>Department</span>
                    <p>{candidateData?.info?.department || "N/A"}</p>
                  </li>
                  <li>
                    <span>Designation</span>
                    <p>{candidateData?.info?.designation || "N/A"}</p>
                  </li>
                  <li>
                    <span>Joining Date</span>
                    <p>
                    {candidateData?.JoiningDate
                        ? moment(candidateData?.JoiningDate).format("DD MMM YYYY")
                        : "N/A"}
                      
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="col-8">
          <div className="Emp-Det-left">
            <div className="accordion" id="EmployeesAccordionRobots">
              {/* Education Accordion */}
              <div className="accordion-item">
                <div className="accordion-header" id="regularHeadingFirst">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseFirst"
                  >
                    Education
                  </button>
                </div>
                <div
                  id="regularCollapseFirst"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Secondary Passing</label>
                        <p>{candidateData?.info?.documents?.secondary_passing || "N/A"}</p>

                      </div>
                      <div className="col-sm-4">
                        <label>Senior Passing</label>
                        <p>{candidateData?.info?.documents?.senior_passing || "N/A"}</p>

                      </div>
                      <div className="col-sm-4">
                        <label>Bachelor’s Passing</label>
                        <p>{candidateData?.info?.documents?.bachelor_passing || "N/A"}</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Experience Accordion */}
              <div className="accordion-item">
                  <div className="accordion-header" id="regularHeadingSecond">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseSecond" aria-expanded="false" aria-controls="regularCollapseSecond">
                      Experience
                    </button>
                  </div>
                  <div id="regularCollapseSecond" className="accordion-collapse collapse" aria-labelledby="regularHeadingSecond" data-bs-parent="#EmployeesAccordionRobots">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Company Name</label>
                            <div className="Emp-form-info">
                            {/* <p>{candidateData?.info?.documents?.aadharcard_no || "N/A"}</p> */}

                              {/* <p>   {documents?.companyname ? documents?.companyname : "NA"} </p> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables"> Start Date </label>
                            <div className="Emp-form-info">
                              {/* <p>    {documents?.start_date ? documents?.start_date : "NA"} </p> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">End Date</label>
                            <div className="Emp-form-info">
                              {/* <p>    {documents?.end_date ? documents?.end_date : "NA"}</p> */}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              <div className="accordion-item">
                  <div className="accordion-header" id="regularHeadingThrid">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseThrid" aria-expanded="false" aria-controls="regularCollapseThrid">
                      Bank Details
                    </button>
                  </div>
                  <div id="regularCollapseThrid" className="accordion-collapse collapse" aria-labelledby="regularHeadingThrid" data-bs-parent="#EmployeesAccordionRobots">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Bank Name </label>
                            <div className="Emp-form-info">
                            <p>{candidateData?.info?.bankdetails?.bank_name || "N/A"}</p>
                           
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Bank Account No. </label>
                            <div className="Emp-form-info">
                            <p>{candidateData?.info?.bankdetails?.account_no || "N/A"}</p>

                 
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">IFSC Code</label>
                            <div className="Emp-form-info">
                            <p>{candidateData?.info?.bankdetails?.ifsc_code || "N/A"}</p>

                          
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">PAN No. </label>
                            <div className="Emp-form-info">
                            <p>{candidateData?.info?.pancard_no || "N/A"}</p>

                           
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Aadhaar No. </label>
                            <div className="Emp-form-info">
                            <p>{candidateData?.info?.aadharcard_no || "N/A"}</p>

                           
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeProfiles;
