import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertMessage,
  Button,
  CardWrapper,
  CoreLoader,
  DownloadIcon,
  GroupInput,
  Input,
  Label,
} from "common/components";
import { AddExperienceModal } from ".";
import apiManager from "networking/baseAPIManager";
import { documentFormSchema } from "utils/formValidationUtils";
import "../../../../assets/style/Responsive.css";
import { IoCheckbox } from "react-icons/io5";

const ExperienceDetails = () => {
  let paramsData = useParams();
  let empid = paramsData.empid;

  const [modalShow, setModalShow] = useState(false);
  const [extraExperienceData, setExtraExperienceData] = useState([]);
  const [experience, setExperience] = useState({});
  const [loading, setLoading] = useState(false);
  const [allApiData, setAllApiData] = useState({});

  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");

  const forminitialValue = {
    companyname: experience?.companyname || "",
    start_date: experience?.start_date || "",
    end_date: experience?.end_date || "",
    payslip: experience?.payslip || "",
    offerletter: experience?.offerletter || "",
    relievingletter: experience?.relievingletter || "",
    // resignationletter: experience?.resignationletter || "",
    // compensation: experience?.compensation || "",
    experienceletter: experience?.experienceletter || "",
  };

  const navigate = useNavigate();
  const { handleChange, handleBlur, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: forminitialValue,
    validationSchema: documentFormSchema,
    onSubmit: (values, action) => {

      handleDocsSubmit(values);
    },
    enableReinitialize: true,
  });

  const getcandidateDocuments = () => {
    setLoading(true);
    apiManager
      .candidateDocuments(empid)
      .then((res) => {
        setLoading(false);
        setExtraExperienceData(structuredClone(res?.data?.data?.experienceData[0]));
        setExperience({ ...res?.data?.data?.experienceData[0] });
        setAllApiData(structuredClone(res?.data));
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    empid && getcandidateDocuments();
  }, [empid]);

  const handleDocsSubmit = (data) => {
    const formData = new FormData();
    formData.append("empid", empid);
    formData.append("companyname", data.companyname);
    formData.append("end_date", data.end_date);
    formData.append("start_date", data.start_date);

    extraExperienceData?.length &&
      extraExperienceData.map((item, index) => {
        Object.keys(item).map((key) => {
          if (typeof item[key] == "object") {
            formData.append([key], item[key]);
          }
        });
      });

    formData.append("compensation", data.compensation);
    formData.append("experienceletter", data.experienceletter);
    formData.append("offerletter", data.offerletter);
    formData.append("payslip", data.payslip);
    formData.append("relievingletter", data.relievingletter);
    formData.append("resignationletter", data.resignationletter);
    if (extraExperienceData?.length) {
      formData.append("extraExperience", JSON.stringify(extraExperienceData));
    }

    setLoading(true);
    apiManager
      .addEmployeeDocuments(formData)
      .then((response) => {
        setLoading(false);
        setShowMSG("Details updated");
        setmsgType("success");
        navigate(`/hrtools/employee-onboarding/candidatePreview/${empid}`);
      })
      .catch((error) => {
        setLoading(false);
        setShowMSG("failed to update Details");
        setmsgType("error");
        console.log(error);
      });
  };

  const renderFileInput = (name, label) => {
    if (values[name] && typeof values[name] == "string") {
      const downloadAttachment = async () => {
        let BASE_URL = process.env.REACT_APP_BASE_URL;
        const attachmentData = `${BASE_URL}/${values[name]}`;

        if (!attachmentData) {
          console.error("No attachment data found.");
          return;
        }

        try {
          const response = await fetch(attachmentData);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", values[name]);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } catch (error) {
          console.error("Error fetching or triggering download:", error);
        }
      };
      return (
        <div className="form-control">
          <div className="downloadAttachmentform">
            {/* {label}  */}

            <Button onClick={() => downloadAttachment()} label="Download" type="button" />
            {allApiData?.data?.status == "pending" || allApiData?.data?.status == "completed"? (
              <span
                className="material-symbols-outlined pointer button"
                onClick={() => {
                  setFieldValue([name], undefined);
                }}
              >
                edit
              </span>
            ) : (
              ""
            )}
          </div>

        </div>
      );
    }
    console.log(extraExperienceData, 'extraExperienceData')
    return (
      <>

        {/* <Label label={label || "Upload Result"} required parentclasses="col-xl-4  col-lg-5 col-md-6" /> */}
        <Input
          type="file"
          parentclasses="col-xl-8 col-lg-12 col-md-12"
          accept="image/*, .pdf"
          name={name}
          onChange={(e) => {
            setFieldValue(name, e.target.files[0]);
          }}
          onBlur={handleBlur}
          isValid={touched[name] && !errors[name]}
          touched={touched[name]}
          error={errors[name]}
        />

      </>
    );
  };

  const renderFileInputloop = (index, key, label) => {
    let item = extraExperienceData[index];
    let image = item[key];

    let labels = {
      offerletter: "Offer Letter",
      payslip: "Pay Slip",
      relievingletter: "Relieving Letter",
      experienceletter: "Experience Letter",
    };

    let labelKey = labels[key] || label || key;

    const downloadAttachment = async () => {
      if (!image) {
        console.error("No attachment data found.");
        return;
      }

      let BASE_URL = process.env.REACT_APP_BASE_URL;
      const attachmentData = `${BASE_URL}/${image}`;

      try {
        const response = await fetch(attachmentData);
        const blob = await response.blob();

        // Determine file type from the blob if available
        const fileType = blob.type || "application/octet-stream";

        // Generate a download URL with the correct MIME type
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        // Extract file extension from the image name
        const fileExtension = image.split(".").pop();

        // Set the correct file name with the extension
        link.setAttribute("download", `${key}.${fileExtension}`);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // Revoke the object URL after download
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error fetching or triggering download:", error);
      }
    };

    if (image) {
      return (
        <div className="w-100 d-flex gap-3 align-items-center mt-2">
          <div className="capitalize w-50 normalBold d-flex align-items-center">{labelKey}</div>

          <Button onClick={downloadAttachment} label="Download" type="button" />

          {allApiData?.data?.status === "pending" && (
            <span
              className="material-symbols-outlined pointer"
              onClick={() => {
                const updatedData = [...extraExperienceData];
                updatedData[index][key] = undefined;
                setExtraExperienceData(updatedData);
              }}
            >
              edit
            </span>
          )}
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center gap-4 py-3">
        
        <Label label={labelKey} required parentclasses="col-xl-3 col-lg-4" />
        <Input
          type="file"
          parentclasses="col-xl-8 co-lg-7"
          accept="image/*, .pdf"
          name={key}
          required
          onChange={(e) => {
            const updatedData = [...extraExperienceData];
            updatedData[index][key] = e.target.files[0];
            setExtraExperienceData(updatedData);
          }}
          onBlur={handleBlur}
          isValid={touched[key] && !errors[key]}
          touched={touched[key]}
          error={errors[key]}
        />
        
      </div>
    );
  };

  const rendermultiFields = () => {
    if (!extraExperienceData || !Array.isArray(extraExperienceData)) {
      return <></>;
    }
    return (
      <>
        {extraExperienceData.map((item, index) => {
          let { companyname, start_date, end_date } = item;

          return (
            <div key={index} className="align-items-center normalCard">
              <div className="d-flex w-100 align-items-end justify-content-end">
                <span
                  className="material-symbols-outlined pointer"
                  onClick={() => {
                    extraExperienceData.splice(index, 1);
                    setExtraExperienceData([...extraExperienceData]);
                  }}
                >
                  close
                </span>
              </div>
              <div className=" col-xl-12 d-flex align-items-center  pb-2">
                <Label label="Company Name" parentclasses="col-xl-1 col-lg-2" />
                <Input
                  parentclasses="col-xl-10 col-lg-8 col-md-6 ms-5 ps-5"
                  placeholder="Company Name"
                  value={companyname || ""}
                  onChange={(e) => {
                    extraExperienceData[index].companyname = e.target.value;
                    setExtraExperienceData([...extraExperienceData]);
                  }}
                />
              </div>
              <div className="">
                <div className="d-flex align-items-center gap-5 py-4">
                  <Label label="Start Date" parentclasses="col-xl-3 col-lg-4 col-md-5" />
                  <GroupInput
                    name="start_date"
                    parentclasses="col-xl-8 col-lg-7 col-md-6"
                    type="date"
                    value={start_date || ""}
                    onChange={(e) => {
                      extraExperienceData[index].start_date = e.target.value;
                      setExtraExperienceData([...extraExperienceData]);
                    }}
                    onBlur={handleBlur}
                    isValid={touched.start_date && !errors.start_date}
                    touched={touched.start_date}
                    error={errors.start_date}
                  />
                </div>
                <div className="d-flex align-items-center gap-5 py-4">
                  <Label label="End Date" parentclasses="col-xl-3 col-lg-4 col-md-5" />
                  <Input
                    parentclasses="col-xl-8 col-lg-7 col-md-6"
                    name="end_date"
                    type="date"
                    label="End Date"
                    value={end_date || ""}
                    onChange={(e) => {
                      extraExperienceData[index].end_date = e.target.value;
                      setExtraExperienceData([...extraExperienceData]);
                    }}
                    onBlur={handleBlur}
                    isValid={touched.end_date && !errors.end_date}
                    touched={touched.end_date}
                    error={errors.end_date}
                  />
                </div>
                {renderFileInputloop(index, "payslip")}
                
                {renderFileInputloop(index, "offerletter")}
                {renderFileInputloop(index, "relievingletter")}
                {/* {renderFileInputloop(index, "resignationletter")} */}
                {/* {renderFileInputloop(index, "compensation")} */}
                {renderFileInputloop(index, "experienceletter")}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <div className="card">
        <div className="Onboarding-title">Candidate Onboarding</div>
        <div className="Onboarding-info-row">
          <div className="Onboarding-check active">
            <IoCheckbox />
            <div className="Onboarding-check-title">Candidate Details</div>
          </div>
          <div className="Onboarding-check active">
            <IoCheckbox />
            <div className="Onboarding-check-title">Current Address</div>
          </div>
          <div className="Onboarding-check active">
            <IoCheckbox />
            <div className="Onboarding-check-title">Education Details</div>
          </div>
          <div className="Onboarding-check active">
            <IoCheckbox />
            <div className="Onboarding-check-title">Experience Details</div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="card mt-4">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <Label label="Company Name" />
                <Input
                  type="text"
                  name="companyname"
                  value={values.companyname || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.companyname && !errors.companyname}
                  touched={touched.companyname}
                  error={errors.companyname}
                  maxLength={70}
                  minLength={3}
                />
              </div>

            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <Label label="Start Date" parentclasses="col-xl-2 col-lg-2  col-md-5 topadding" />
                <Input
                  parentclasses="col-xl-4  col-lg-4 col-md-7 topadding"
                  className="form-control"
                  name="start_date"
                  type="date"
                  value={values.start_date || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.start_date && !errors.start_date}
                  touched={touched.start_date}
                  error={errors.start_date}
                  min="1990-01-01"
                  max="2999-12-31"
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <Label label="End Date" parentclasses="col-xl-2 col-lg-2  col-md-5  topadding " />
                <Input
                  parentclasses="col-xl-4 col-lg-4  col-md-7 topadding"
                  className="form-control"
                  name="end_date"
                  type="date"
                  value={values.end_date || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.end_date && !errors.end_date}
                  touched={touched.end_date}
                  error={errors.end_date}
                  min="1990-01-01"
                  max="2999-12-31"
                />
              </div>
            </div>


            <div className="col-sm-6">
              <div className="form-group">
                <label className="lables" > Pay slip </label>
                {renderFileInput("payslip", "Payslip")}
               <p>
                <span className="filesize">Maximum file size allowed : 2MB.</span>
               </p>
              </div>
            </div>


            <div className="col-sm-6">
              <div className="form-group">
                <label className="lables" > Offer letter </label>
                {renderFileInput("offerletter", "Offer letter")}
                <p>

                <span className="filesize">Maximum file size allowed : 2MB.</span>
                </p>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label className="lables" > Relieving letter </label>
                {renderFileInput("relievingletter", "Relieving letter")}
                <p>

                <span className="filesize">Maximum file size allowed : 2MB.</span>
                </p>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label className="lables" > Experience letter </label>
                {renderFileInput("experienceletter", "Experience letter")}{" "}
                <p>

       <span className="filesize">Maximum file size allowed : 2MB.</span>
                </p>
              </div>
            </div>
          </div>



        </div>

        {rendermultiFields()}
        {/* <div className="d-flex gap-3 justify-content-end px-4">
          <Button
            type="button"
            label="Add Experience"
            onClick={() => {
              setModalShow(!modalShow);
            }}
          />
        </div> */}

        {modalShow && (
          <div className="mt-3">
            <AddExperienceModal
              show={modalShow}
              setShow={setModalShow}
              data={extraExperienceData}
              setData={setExtraExperienceData}
            />
          </div>
        )}
        <div
          className="button-container d-flex gap-3 justify-content-end mt-4"
        >
          <Button
            classes="responsiver-button"
            type="button"
            label="Back"
            onClick={() => navigate(`/hrtools/employee-onboarding/educationdetails/${empid}`)}
          />
          <Button
            type="submit"
            label="Save & Continue"
            classes="responsiver-button btn-Continue"
          />
          {allApiData?.nextPage ? (
            <Button
              classes="responsiver-button"
              type="button"
              label="Next"
              onClick={() => navigate(`/hrtools/employee-onboarding/candidatePreview/${empid}`)}
            />
          ) : (
            ""
          )}
        </div>
      </form>
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default ExperienceDetails;
