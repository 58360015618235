import { MdOutlineDashboard, MdHome, MdOutlineExitToApp } from "react-icons/md";
import { BsCurrencyRupee, BsPersonCheck, BsPersonPlus, BsFillPersonFill, RiCupLineRiCupLine , BsCash } from "react-icons/bs";
import { TbLogout, TbSettingsPlus, TbUsers } from "react-icons/tb";
import { IoDocumentTextOutline } from "react-icons/io5";
import isResourceAvailable from "utils/permit_util";
import { LuPencil } from "react-icons/lu";
import { RiCupLine } from "react-icons/ri";
const rolePermissions = isResourceAvailable("role");
export const employeeDashboardSideMenu = [
  // { id: 1, title: "Home", path: "/employee/dashboard", icon: <MdHome /> },

  // { id: 3, title: "Profile", path: "/hrtools/employee-profile", icon: <BsFillPersonFill /> },
 
  // { id: 5, title: "Organisation", path: "/", icon: <GiOrganigram /> },
  // { id: 6, title: "Department", path: "/", icon: <HiOutlineOfficeBuilding /> },
  { id: 7, title: "Attendance", path: "/hrtools/employee/attendance-dashboard", icon: <BsPersonCheck /> },
  { id: 8, title: "Leave", path: "/employee/attendance-tracking/leave-request", icon: <TbLogout /> },
  { id: 4, title: "Employee Profile", path: "/employee/profiles", icon: <BsFillPersonFill />  },
  { id: 10, title: "Payroll",subLinks: [
    // { id: 1, title: " Payroll", link: "/Employee/payroll" },
    { id: 1, title: " Advance Salary", link: "Employee/advancedsalary" },
  ], path: "/Employee/payroll", icon: <BsCash /> },
  { id: 9, title: "Project", path: "/", icon:<LuPencil /> },
  { id: 8, title: "E-Exit", path: "/hrtools/EmployeeResignation/e-exit/Offboarding", icon: <MdOutlineExitToApp /> },

  
  
    
  { id: 11, title: "Help", path: "/", icon: <RiCupLine/> },
  // { id: 12, title: "Settings", path: "/", icon: <IoSettings /> },
];

export const dashboardSideMenu = [
  // { id: 0, title: "Dashboard", path: "/", icon: <MdOutlineDashboard /> },
  {
    id: 1,
    title: "Candidate Onboarding",
    path: "/hrtools/employee-onboarding/",
    icon: <BsPersonPlus />,
  },
  { id: 2, title: "Generate Documents", path: "/hrtools/generate-documents", icon: <IoDocumentTextOutline /> },
  { id: 3, title: "Employee Profile", path: "/hrtools/employee-profile", icon: <TbUsers /> },
  {
    id: 4,
    title: "Attendance Tracking",
    subLinks: [
      { id: 1, title: "Mark Attendance", link: "/hrtools/attendance/attendance-markup", icon: <BsPersonCheck /> },
      { id: 2, title: " Attendance Report", link: "/hrtools/attendance/attendance-report" },
      { id: 3, title: "Leave Request", link: "/hrtools/attendance-tracking/leave-request" },
      { id: 4, title: "Holiday Calendar", link: "/hrtools/attendance/holiday-calendar" },
      // { id: 1, title: "Leave Report", link: "" },
    ],
    path: "/hrtools/attendance-dashboard",
    icon: <BsPersonCheck />,
  },
  {
    id: 5,
    title: "Manage Fund",
    subLinks: [
      { id: 1, title: "Pay Roll", link: "/hrtools/manage-fund/payroll" },
      { id: 1, title: " Advance Salary", link: "/hrtools/manage-fund/advance-salary" },
    ],
    path: "/hrtools/manage-fund",
    icon: <BsCurrencyRupee />,
  },
  {
    id: 6,
    title: "Assets Management",
    subLinks: [
      { id: 1, title: "Add Asset", link: "/hrtools/assets-management/add-asset" },
      { id: 2, title: "Pending Asset Verification", link: "/hrtools/assets-management/pending-asset-verification" },
      { id: 3, title: "Inventory", link: "/hrtools/assets-management/inventory" },
      { id: 4, title: "Asset  Assignment", link: "/hrtools/assets-management/asset-assignment" },
    ],
    path: "/hrtools/assets-management",
    icon: <TbSettingsPlus />,
  },
  {
    id: 7,
    title: "E-Exit/Off-Boarding",
    path: "/hrtools/e-exit/Offboarding",
    // subLinks: [{ id: 1, title: "Full & Final Process", link: "/hrtools/e-exit/f&f-process" }],
    icon: <MdOutlineExitToApp />,
  },
];

export const subHeaderMenu = [
  { path: "/hrtools/attendance-dashboard", title: "Attendance Tracking" },
  { path: "/hrtools", title: "HR Dashboard" },
  { path: "/", title: "Employee Dashboard" },
  { path: "/hrtools/attendance/attendance-markup", title: "Mark Attendance" },
  { path: "/hrtools/attendance/attendance-report", title: "Attendance Report (Monthly)" },
  { path: "/hrtools/attendance/attendance-report-monthly", title: "Attendance Report (Monthly)" },
  { path: "/hrtools/attendance/attendance-report-weekly", title: "Attendance Report (Weekly)" },
  { path: "/hrtools/attendance-tracking/leave-request", title: "Leave Request" },
  { path: "/hrtools/attendance/holiday-calendar", title: "Holiday Calendar" },
  { path: "/hrtools/manage-fund", title: "Manage Fund" },
  { path: "/hrtools/manage-fund/payroll", title: "Payroll" },
  { path: "/hrtools/manage-fund/edit-salary", title: "Approve Salary" },
  { path: "/hrtools/manage-fund/edit-salary/form", title: "Edit Salary" },
  { path: "/hrtools/generate-documents/employee-details", title: "Generate Documents" },
  { path: "/hrtools/generate-documents/employee-letter", title: "Generate Documents" },
  { path: "/hrtools/manage-fund/advance-salary", title: "Advance Salary" },
  { path: "/hrtools/employee-onboarding/", title: "Employee Onboarding" },
  { path: "/hrtools/employee-profile", title: "Employee Profile" },
  { path: "/employee/profiles", title: "My Profile" },
  { path: "/employee/dashboard", title: "Employee Dashboard" },
  { path: "/employee/attendance-tracking/leave-request", title: "Leave" },
  { path: "/Employee/payroll", title: "Payroll" },

  { path: "/hrtools/assets-management", title: "Assets Management" },
  { path: "/hrtools/assets-management/add-asset", title: "Add Asset" },
  { path: "/hrtools/assets-management/pending-asset-verification", title: "Pending Asset Verification" },
  { path: "/hrtools/assets-management/inventory", title: "Inventory" },
  { path: "/hrtools/assets-management/asset-assignment", title: "Asset Assignment" },
  { path: "/hrtools/generate-documents", title: "Generate Documents" },
  { path: "/hrtools/e-exit/Offboarding", title: "E-exit" },
  { path: "/hrtools/e-exit/f&f-process", title: "Full & Final Process" },
  { path: "/hrtools/attendance/holidayform", title: "Add Holidays" },
  { path: "/hrtools/attendance/holiday-list-calendar/", title: "Holidays Calendar" },
];
