
// import React, { useEffect, useState } from "react";
// import { Breadcrumb } from "rsuite";
// import { FaEye } from "react-icons/fa";
// import { AlertMessage, Button } from "common/components";
// import SelectPickerInput from "common/components/SelectPicker";
// import { Table } from "UI_Components";
// import AdvanceSalaryRequest from "Components/ManageFund/AdvanceSalary/AdvanceSalaryRequest";
// import apiManager from "networking/baseAPIManager";
// import EmployeeAdvanceSalaryView from "./EmployeeAdvanceSalaryView";

// const AdvancedSalary = () => {
//   const [loading, setLoading] = useState(false);
//   const [advance, setAdvanceData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [statusFilter, setStatusFilter] = useState("");
//   const [showAddLeave, setShowAddLeave] = useState(false);
//   const [open, setOpen] = React.useState(false);
//   const [modalContent, setModalContent] = useState({});
//    const [view, setView] = useState(true);
//   const moment = require("moment");
//   const dateHtml = (date) => moment(date).format("DD MMM YYYY");

//   const handleItemClick = (data) => {
//     console.log("Clicked data:", data); // Check if data is being passed
//     setModalContent(data);
//     setOpen(true); // Ensure this triggers the modal
//     setView(true);
//   }

//   const customHtml = (data) => {
//     return (
//       <Button
//         onClick={() => handleItemClick(data)}
//         label={
//           <>
//             <FaEye style={{ marginRight: "5px" }} /> View
//           </>
//         }
//       />
//     );
//   };

//   const columns = [
//     { header: "Salary Type", field: "advance_salary_type" },
//     { header: "Amount", field: "amount" },
//     { header: "Instalment", field: "instalment" },
//     { header: "EMI Amount", field: "emi_amount" },
//     {
//       header: "Status",
//       field: "status",
//       customHtml: (data) => {
//         const statusColor =
//           data.status.toLowerCase() === "approved"
//             ? "green"
//             : data.status.toLowerCase() === "rejected"
//             ? "red"
//             : "blue";
//         return <span style={{ color: statusColor }}>{data.status}</span>;
//       },
//     },
//     { header: "Actions", field: "actions", customHtml },
//   ];

//   useEffect(() => {
//     fetchAdvanceSalaryData();
//   }, []);

//   const fetchAdvanceSalaryData = () => {
//     setLoading(true);
//     apiManager
//       .EmployeeAdvance()
//       .then((response) => {
//         setLoading(false);
//         const listData = response.data.List || [];
//         setAdvanceData(listData);
//         setFilteredData(listData);
//       })
//       .catch((error) => {
//         setLoading(false);
//         console.error(error);
//       });
//   };

//   const handleStatusFilter = (value) => {
//     setStatusFilter(value);

//     // Filter data based on selected status
//     if (value) {
//       const filtered = advance.filter(
//         (item) => item.status.toLowerCase() === value.toLowerCase()
//       );
//       setFilteredData(filtered);
//     } else {
//       // If no status selected, show all data
//       setFilteredData(advance);
//     }
//   };

//   const handleAddLeaveClick = () => {
//     setShowAddLeave(true);
//   };

//   const handleCloseModal = () => {
//     setShowAddLeave(false);
//   };

//   return (
//     <>
//       <div className="container-fluid px-2">
//         <div className="row pt-3 m-0">
//           <div className="col-lg-8 col-md-8 col-sm-12 gap-2">
//             <Breadcrumb separator=">">
//               <Breadcrumb.Item
//                 href="/employee"
//                 style={{
//                   color: "gray",
//                   fontSize: "1.25rem",
//                   textDecoration: "none",
//                 }}
//               >
//                 Home
//               </Breadcrumb.Item>
//               <Breadcrumb.Item
//                 active
//                 style={{ color: "orange", fontSize: "1.25rem" }}
//               >
//                 Advanced Salary
//               </Breadcrumb.Item>
//             </Breadcrumb>
//           </div>
//           <div className="col-lg-4 col-md-4 d-flex justify-content-end">
//             <Button
//               label="Create Advance Salary"
//               onClick={handleAddLeaveClick}
//             />
//           </div>
//         </div>

//         <div className="border rounded shadow p-3 bg-white mx-2 mb-2">
        
//           <div className="row m-0 p-0">
//           <div className="col-6">
//             <div className="card-top-head">
//               <div className="card-title">    Advanced Salary</div>
//               {/* <div className="sub-title">{moment().format("DD MMM YYYY")}</div> */}
//             </div>
//           </div>
//           <div className="col-6">
//             <div className="row">
             
//               <div className="col-6">
//               <SelectPickerInput
//                 sidelabel="Sort by Status"
//                 defaultselect=""
//                 data={[
//                   { label: "All", value: "" },
//                   { label: "Approved", value: "approved" },
//                   { label: "Rejected", value: "rejected" },
//                   { label: "Cancelled", value: "cancelled" },
//                 ]}
//                 value={statusFilter}
//                 valueKey="value"
//                 className="w-100"
//                 onChange={handleStatusFilter}
//               />
//               </div>
//             </div>
//           </div>
//         </div>
//           <div className="row m-0">
//             <Table
//               columns={columns}
//               Data={filteredData}
//               btn_name="view"
//               LeaveTableHeight="LeaveTableHeight"
//               custom_btn="custom_btn"
//               custom_btn2="custom_btn2"
//             />
//           </div>
//         </div>
//       </div>

//       {showAddLeave && (
//         <AdvanceSalaryRequest
//           openModal={showAddLeave}
//           closeModal={handleCloseModal}
//         />
//       )}
//       {open && (
//         <EmployeeAdvanceSalaryView
//           Data={modalContent}
//           setOpen={setOpen}
//           fetchapi={fetchAdvanceSalaryData}
//         />
//       )}
//     </>
//   );
// };

// export default AdvancedSalary;
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "rsuite";
import { FaEye } from "react-icons/fa";
import { AlertMessage, Button } from "common/components";
import SelectPickerInput from "common/components/SelectPicker";
import { Table } from "UI_Components";
import AdvanceSalaryRequest from "Components/ManageFund/AdvanceSalary/AdvanceSalaryRequest";
import apiManager from "networking/baseAPIManager";
import EmployeeAdvanceSalaryView from "./EmployeeAdvanceSalaryView";

const AdvancedSalary = () => {
  const [loading, setLoading] = useState(false);
  const [advance, setAdvanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [showAddLeave, setShowAddLeave] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const moment = require("moment");

  const dateHtml = (date) => moment(date).format("DD MMM YYYY");

  const handleItemClick = (data) => {
    setModalContent(data);
    setOpenModal(true);
  };

  const customHtml = (data) => (
    <Button
      onClick={() => handleItemClick(data)}
      label={
        <>
          <FaEye style={{ marginRight: "5px" }} /> View
        </>
      }
    />
  );

  const columns = [
    { header: "Salary Type", field: "advance_salary_type" },
    { header: "Amount", field: "amount" },
    { header: "Instalment", field: "instalment" },
    { header: "EMI Amount", field: "emi_amount" },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        const statusColor =
          data.status.toLowerCase() === "approved"
            ? "green"
            : data.status.toLowerCase() === "rejected"
            ? "red"
            : "blue";
        return <span style={{ color: statusColor }}>{data.status}</span>;
      },
    },
    { header: "Actions", field: "actions", customHtml },
  ];

  useEffect(() => {
    fetchAdvanceSalaryData();
  }, []);

  const fetchAdvanceSalaryData = () => {
    setLoading(true);
    apiManager
      .EmployeeAdvance()
      .then((response) => {
        setLoading(false);
        const listData = response.data.List || [];
        setAdvanceData(listData);
        setFilteredData(listData);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);

    // Filter data based on selected status
    if (value) {
      const filtered = advance.filter(
        (item) => item.status.toLowerCase() === value.toLowerCase()
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(advance);
    }
  };

  const handleAddLeaveClick = () => {
    setShowAddLeave(true);
  };

  const handleCloseModal = () => {
    setShowAddLeave(false);
  };
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="container-fluid px-2">
        <div className="row pt-3 m-0">
          <div className="col-lg-8 col-md-8 col-sm-12 gap-2">
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/employee" style={{ color: "gray", fontSize: "1.25rem", textDecoration: "none" }}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active style={{ color: "orange", fontSize: "1.25rem" }}>
                Advanced Salary
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="col-lg-4 col-md-4 d-flex justify-content-end">
            <Button label="Create Advance Salary" onClick={handleAddLeaveClick} />
          </div>
        </div>

        <div className="border rounded shadow p-3 bg-white mx-2 mb-2">
          <div className="row m-0 p-0">
            <div className="col-6">
              <div className="card-top-head">
                <div className="card-title">Advanced Salary</div>
              </div>
            </div>
            <div className="col-6">
              <SelectPickerInput
                sidelabel="Sort by Status"
                defaultselect={currentYear}
                data={[
                  { label: "All", value: "" },
                  { label: "Approved", value: "approved" },
                  { label: "Rejected", value: "rejected" },
                  { label: "Cancelled", value: "cancelled" },
                ]}
                value={statusFilter}
                valueKey="value"
                className="w-100"
                onChange={handleStatusFilter}
              />
            </div>
          </div>
          <div className="row m-0">
            <Table columns={columns} Data={filteredData} />
          </div>
        </div>
      </div>

      {showAddLeave && (
        <AdvanceSalaryRequest openModal={showAddLeave} closeModal={handleCloseModal} />
      )}
      {openModal && (
        <EmployeeAdvanceSalaryView
          Data={modalContent}
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          fetchapi={fetchAdvanceSalaryData}
        />
      )}
    </>
  );
};

export default AdvancedSalary;
