import { AlertMessage, Button, CommonModal, Dropdown } from "common/components";
import SelectPickerInput from "common/components/SelectPicker";
import { SearchFilter } from "Components/AttendaceReport";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { Breadcrumb } from "rsuite";
import { SubHeader, Table } from "UI_Components";
import PayrollForm from "./PayrollForm";
import { useLocation } from "react-router-dom";

const Payroll = () => {
  const [loading, setLoading] = useState(false);
  const [payrollData, setPayrollData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);


  const moment = require("moment");
  const location = useLocation();

  const years = Array.from({ length: 3 }, (_, index) => currentYear - index);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM"));

  // const getYearOptions = () => {
  //   const currentYear = new Date().getFullYear();
  //   return Array.from({ length: 6 }, (_, i) => currentYear - i).map((year) => ({
  //     value: year,
  //     label: year.toString(),
  //   }));
  // };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const getMonthOptions = () => {
    return moment.months().map((month) => ({
      value: month,
      label: month,
    }));
  };
  const handleItemClick = (data) => {
    setModalContent(data);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalContent(null);
  };

  const customHtml = (data) => (
    <Button
      onClick={() => handleItemClick(data)}
      label={
        <>
          View
        </>
      }
    />
  );

  // Table columns
  const columns = [
    { header: "Month", field: "month" , customHtml: (data) => moment().month(data.month - 1).format("MMM"),},
    { header: "Present Days", field: "presentDay" },
    { header: "Absent Days", field: "end_date" },
    { header: "Net Pays", field: "netSalary" },
    { header: "Deduction", field: "totaldeduction" },
    {
      header: "Status",
      field: "salary_status",
      customHtml: (data) => {
        const statusColor =
          data.salary_status.toLowerCase() === "paid"
            ? "actionsPending"
            : data.salary_status.toLowerCase() === "rejected"
            ? "rejectedClass"
            : "rejectedClass";
        return <span className={statusColor}>{data.salary_status}</span>;
      },
    },
    { header: "Actions", field: "actions", customHtml: customHtml },
  ];

  useEffect(() => {
    EmployeePayrollList();
  }, []);

  const EmployeePayrollList = () => {
    const monthNumber = moment().month(selectedMonth).format("M");
    setLoading(true);
    apiManager
      .EmployeePayroll({ month: monthNumber, year: selectedYear })
      .then((response) => {
        setLoading(false);
        setPayrollData(response?.data?.SalaryList || []);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const filteredData = payrollData.filter((entry) => {
    return (
      moment(entry.date).year() === parseInt(selectedYear) &&
      moment(entry.date).format("MMMM") === selectedMonth
    );
  });
  const handleYearChange = (value) => {
    const newSelectedYear = value != null ? value : currentYear;
    setSelectedYear(newSelectedYear);
  };
  let dropdownData = years.map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));
  return (
    <>
      <div className="container-fluid px-2">
        <div className="AttendanceReporttop">
          <SubHeader location={location} />
        </div>
      
      
        <div className="card-top align-items-center">
          <div className="card-head-left">
            <div className="card-title">Payroll</div>
            {/* <div className="sub-title">{moment().format("DD MMM YYYY")}</div> */}
          </div>

          <div className="card-head-right">
            <Dropdown
              parentclasses=""
              value={selectedMonth}
              onChange={handleMonthChange}
              options={getMonthOptions()}
              placeholder="Month"
              sidelabel
            />
           <SelectPickerInput
              sidelabel="Select Year"
              defaultselect={currentYear}
              data={dropdownData}
              value={selectedYear}
              valueKey={"value"}
              onChange={handleYearChange}
            />
          </div>
        </div>
        <div className="card">
          <Table
            columns={columns}
            Data={filteredData}
            btn_name="view"
            LeaveTableHeight="LeaveTableHeight"
            custom_btn="custom_btn"
            custom_btn2="custom_btn2"
          />
        </div>
      </div>
      {/* Modal for payroll details */}
      <CommonModal
        open={isOpen}
        onClose={closeModal}
        size="lg"
        title="Employee Info"
      >
        <PayrollForm data={modalContent} />
      </CommonModal>
    </>
  );
};

export default Payroll;
