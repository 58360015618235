import React, { useEffect, useState } from "react";

import "../../assets/style/attendance/drp-dwn.css";
import { Button, Dropdown } from "common/components";

import moment from "moment";

export const FilterReport = ({ handleSubmit, filterData, setFilterData }) => {
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const { year, month } = filterData;
  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 6 }, (_, i) => currentYear - i).map((year) => ({
      value: year,
      label: year.toString(),
    }));
  };
  const getMonthOptions = () => {
    return moment.months().map((month, index) => {
      return {
        value: index + 1,
        label: month,
        name: month,
      };
    });
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = moment().format("MMMM");
    setFilterData({ year: currentYear, month: currentMonth });
  }, [setFilterData]);

  const handleYearChange = (event) => {
    setFilterData((prev) => ({ ...prev, year: event.target.value }));
  };

  const handleMonthChange = (event) => {
    console.log(event.target.value, "event event");
    setFilterData((prev) => ({ ...prev, month: event.target.value }));
  };
  const onSubmit = () => {
    handleSubmit(filterData);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-lg-2 col-md-4">
            <Dropdown parentclasses="" value={""} onChange={""} options={""} placeholder="HR" sidelabel />
          </div>
          <div className="col-lg-4 col-md-8">
            <Dropdown
              parentclasses=""
              value={""}
              onChange={""}
              options={""}
              placeholder="Deveploment Program"
              sidelabel
            />
          </div> */}

          <div className="col-lg-2 col-md-4 repoartmonth">
            <Dropdown
              parentclasses=""
              value={month}
              onChange={handleMonthChange}
              options={getMonthOptions()}
              placeholder="Month"
              sidelabel
            />
          </div>
          <div className="col-lg-2 col-md-4 repoartmonth">
            <Dropdown
              parentclasses=""
              value={year}
              onChange={handleYearChange}
              options={getYearOptions()}
              placeholder="Year"
              sidelabel
            />
          </div>
          <div className="col-lg-2 col-md-4 repoartmonth">
            <Button label="Report" onClick={onSubmit} />
          </div>
        </div>
      </div>
    </>
  );
};
