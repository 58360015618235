import { closeIcon } from "assets/images";
import { Button, Input, Label } from "common/components";
import React, { useState } from "react";

function AddExperienceModal(props) {
  const { setShow, data, setData } = props;
  const [fieldValues, setFieldValues] = useState({});
  const [errors, setErrors] = useState({});

  let tmpData = data ? [...data] : [];

  const handleChange = (e, field, isFile = false) => {
    const value = isFile ? e.target.files[0] : e.target.value;
    setFieldValues({ ...fieldValues, [field]: value });
  };

  const handleSave = () => {
    let errors = {};
    const today = new Date();
    const companyNameRegex = /^[^\p{Emoji}]+$/u;

    // Company name validation
    if (!fieldValues.companyname) {
      errors.companyname = "This field is required";
    } else if (!companyNameRegex.test(fieldValues.companyname)) {
      errors.companyname = "Company name must not contain emojis";
    }

    // Start date validation
    if (!fieldValues.start_date) {
      errors.start_date = "This field is required";
    } else {
      const startDate = new Date(fieldValues.start_date);
      if (startDate > today) {
        errors.start_date = "Start date cannot be in the future";
      }
    }

    // End date validation
    if (!fieldValues.end_date) {
      errors.end_date = "This field is required";
    } else {
      const startDate = new Date(fieldValues.start_date);
      const endDate = new Date(fieldValues.end_date);
      if (endDate > today) {
        errors.end_date = "End date cannot be in the future";
      } else if (endDate < startDate) {
        errors.end_date = "End date cannot be before start date";
      }
    }

    // File fields validation
    const fileFields = [
      "payslip",
      "offerletter",
      "relievingletter",
      "resignationletter",
      "compensation",
      "experienceletter",
    ];
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSize = 2 * 1024 * 1024;

    console.log(fieldValues,'fieldValuesfdghhgf')
    fileFields.forEach((field) => {
      const file = fieldValues[field];
      if (!file) {
        errors[field] = "This field is required";
      } else {
        if (!allowedTypes.includes(file.type)) {
          errors[field] = "File must be a JPG, PNG, or PDF";
        }
        if (file.size > maxSize) {
          errors[field] = "File size must be less than 2 MB";
        }
      }
    });

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    tmpData.push(fieldValues);
    setData(tmpData);
    setShow(false);
  };

  const fileFields = [
    "payslip",
    "offerletter",
    "relievingletter",
    "resignationletter",
    "compensation",
    "experienceletter",
  ];

  return (
    <div className="d-flex align-items-center justify-content-center mb-4">
      <div className="relative pt-4 bordermodal">
        <div className="closeIcon" onClick={() => setShow(false)}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="px-4 pt-4">
          <Label labelclasses="pb-1" label="Company Name" />
          <Input
            required
            type="text"
            value={fieldValues.companyname || ""}
            maxLength={70}
            onChange={(e) => handleChange(e, "companyname")}
            touched={true}
            error={errors.companyname}
          />
          <div className="twoboxes mt-4">
            <div>
              <Label labelclasses="pb-1" label="Start Date" />
              <Input
                required
                type="date"
                value={fieldValues.start_date || ""}
                onChange={(e) => handleChange(e, "start_date")}
                touched={true}
                error={errors.start_date}
                min="2000-01-01"
                max="2999-12-31"
              />
            </div>
            <div>
              <Label labelclasses="pb-1" label="End Date" />
              <Input
                required
                type="date"
                value={fieldValues.end_date || ""}
                onChange={(e) => handleChange(e, "end_date")}
                touched={true}
                error={errors.end_date}
                min="2000-01-01"
                max="2999-12-31"
              />
            </div>
            {fileFields.map((field, index) => (
              <div key={index}>
                <Label labelclasses="pb-1" label={field.replace(/([A-Z])/g, " $1").trim()} />
                <Input
                  accept="image/*, .pdf"
                  type="file"
                  required
                  onChange={(e) => handleChange(e, field, true)}
                  touched={true}
                  error={errors[field]}
                />
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end pt-4">
            <Button onClick={handleSave} type="button" label="Save" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddExperienceModal;
