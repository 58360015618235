import React, { useState } from "react";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { FiMessageSquare } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../assets/style/Responsive.css";
import profileimg from "../assets/images/profileimg.png";
import isResourceAvailable from "utils/permit_util";
import Cookies from "js-cookie";

const Header = () => {
  const navigate = useNavigate();
  const rolePermission = isResourceAvailable("role");
  const userName = JSON.parse(sessionStorage.getItem("userName"))
  const cookiesName = Cookies.get("userName");
  const logoDashboard = () => {
    if (rolePermission === "ADMIN") {
      window.location.href = '/hrtools'
    } else {
      navigate('/employee/dashboard')
    }
  }


  return (
    <>


      <nav className="navbar header ">

        <div className="logo">
          <a onClick={() => logoDashboard()}>
            <img src="/HRMS_LOGO.svg" alt="logo" />
          </a>
        </div>



        <div className="navbar-right">
          <div className="navbarmenu">
            <ul>
              <li className="NotificationsOutline">
                <a href="#">
                  <span>21</span>
                  <MdOutlineNotificationsActive />
                </a>
              </li>

              <li className="MessageSquare">
                <a href="#">
                  <span>20</span>
                  <FiMessageSquare />
                </a>
              </li>


            </ul>
          </div>

          <div className="profile-head">
            <div className="profile-name">Hello, <span>{userName || cookiesName}</span></div>
            <div className="profile-img">
              <img src={profileimg} alt="" />
            </div>
          </div>
        </div>

      </nav>

    </>
  );
};

export default Header;
