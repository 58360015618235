import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/style/leaverequest.css";

import useremployee from "../../../assets/images/useremployee.png";
import LeaveEmployee from "../../../assets/images/LeaveEmployee.png";
import EmployeeOnboarding from "../../../assets/images/EmployeeOnboarding.png";
import ProbationEmployee from "../../../assets/images/ProbationEmployee.png";
import { AlertMessage, Button, CommonModal } from "common/components";
import CreateRequest from "Components/LeaveRequest/CreateRequest";
import { SCard, SubHeader, Table } from "UI_Components";
import { Breadcrumb } from "rsuite";
import SelectPickerInput from "common/components/SelectPicker";
import { SearchFilter } from "Components/AttendaceReport";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";


import LeaveView from "Components/LeaveRequest/LeaveView";
import ConfirmationModal from "common/components/ConfirmationModal";
import apiManager from "networking/baseAPIManager";

const EmployeeLeaveRequest = ({ fetchapi }) => {
  const [loading, setLoading] = useState(false);
  const [LeaveData, setLeaveData] = useState();
  const [leaveCountData, setLeaveCountData] = useState();

 const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [searchString, setSearchString] = useState("");
  const [view, setView] = useState(true);
  const [deleteModal, setDeleteModal] = useState(true);
  const [cancelledId, setcancelled] = useState();
  const [confirm, setConfirm] = useState(false);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [showAddLeave, setShowAddLeave] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = useState({});
  const moment = require("moment");
  const dateHtml = (date) => moment(date).format("DD MMM YYYY");
  console.log(leaveCountData?.totalLeavesPerYear,"nmhjhj");
  const location = useLocation();
  const customHtml = (data) => {
    return     <Button className='button btn-Eye'
    onClick={() => handleItemClick(data)}
    label={
      <>
        <IoEyeOutline /> 

      </>
    }
  />
  };
  const customHtml1 = (data) => {
    return     <Button className='button btn-Trash'
    onClick={() => handleDeleteModal(data)} 
    disabled={data.status.toLowerCase() !== "pending"}
    label={
      <>
         <FaRegTrashAlt /> 

      </>
    }
  />
  };
  const customHtml2 = (data) => (
    <div className="d-flex gap-3 justify-content-center">
      {customHtml(data)}
      {customHtml1(data)}
    </div>
  );
  const handleAddLeaveClick = () => {
    setShowAddLeave(true); // Show the AddLeaveForm component
    
  };

  const handleCloseModal = () => {
    setShowAddLeave(false); // Close the modal
  };
  const tools = [
    {
      cardTitle: "Total Leave",
      cardContent: `${leaveCountData?.totalLeavesPerYear || 0}`,
      image: useremployee,
    },
    {
      cardTitle: "Remaining  Leave",
      cardContent:`${leaveCountData?.remainingLeaves || 0}`,
      image: LeaveEmployee,
    },
    {
      cardTitle: "Taken leave",
      image: EmployeeOnboarding,
      cardContent: `${leaveCountData?.takenLeaves || 0}`,
    },
    {
      cardTitle: "Paid Leave",
      image: ProbationEmployee,
      cardContent: `${leaveCountData?.paidLeaves || 0}`,
    },
  ];
  const columns = [
    { header: "Leave Type", field: "type" },
    {
      header: "From",
      field: "start_date",
      customHtml: (data) => dateHtml(data.start_date),
    },
    {
      header: "To",
      field: "end_date",
      customHtml: (data) => dateHtml(data.end_date),
    },
    { header: "Approved By", field: " approved_by_name" },
    { header: "Total Leave", field: "leave_days" },

    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        const statusColor =
          data.status.toLowerCase() === "approved"
            ? "approvedClass"
            : data.status.toLowerCase() === "rejected"
            ? "rejectedClass"
            : "rejectedClass";

        return <span className={statusColor}>{data.status}</span>;
      },
    },
    { header: "Actions", field: "actions", btnType: "primary", customHtml: customHtml2 },
  ];
  useEffect(()=>{EmployeeLeaveList() 
    EmployeeLeavecount()
  }, [selectedYear]);
  const EmployeeLeaveList = () => {
    setLoading(true);

    apiManager
    .Employeeleavedashboard()
    
    .then((response) => {
      setLoading(false);
      setLeaveData(response.data.leave);
  
      
     
      
    })
    .catch((error) => {
      setLoading(false);
      console.error(error);
    });
};


    

  const EmployeeLeavecount = () => {
    setLoading(true);

    apiManager
      .EmployeeleaveConut()
      .then((response) => {
        setLoading(false);
        setLeaveCountData(response.data);
      
        
       
        
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
      setView(true);
    setDeleteModal(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteModal = (data) => {
    if (data.status.toLowerCase() === "pending") {
      setOpen(true);
      setView(false);
      setDeleteModal(true);
      setcancelled(data._id);
    }
  };
  const cancelstatus = () => {
    let dataPayload = {
      leaveid: cancelledId,
      status: "cancelled",
    };

    apiManager
      .leaveStatus(dataPayload)
      .then((res) => {
        setLoading(false);
        fetchapi && fetchapi();
        setmsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
      });
  };

  useEffect(() => {
      confirm === true && cancelstatus();
      setConfirm(false);
    }, [confirm === true]);
    const filteredData = LeaveData?.filter((item) => {
      const itemYear = moment(item.start_date).year(); // Extract year from start_date
      return (
        itemYear === parseInt(selectedYear) && // Filter by selected year
        (item.status.toLowerCase().includes(searchString.toLowerCase()) ||
          item.type.toLowerCase().includes(searchString.toLowerCase()))
      );
    });

    const handleYearChange = (value) => {
      const newSelectedYear = value != null ? value : currentYear;
      setSelectedYear(newSelectedYear);
    };
  const years = Array.from({ length: 3 }, (_, index) => currentYear - index);

    let dropdownData = years.map((year) => ({ label: year.toString(), value: year.toString() }));
  return (
    <>
    <div className="container-fluid">
    <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
    {/* <SubHeader location={location} /> */}

      <div className="AttendanceReporttop">
        
            <SubHeader location={location} />
   
        <div className="col-4 text-end">
          <Button label="Add Leave" onClick={handleAddLeaveClick} />
          <CreateRequest
            openModal={showAddLeave}
            closeModal={handleCloseModal}
            EmployeeLeaveList={EmployeeLeaveList}
          />
        </div>
      </div>

  <div><SCard tools={tools} /></div>
          
   
          <div className="card-top align-items-center">
             <div className="card-head-left">
              <div className="card-title"> Leave List</div>
              {/* <div className="sub-title">{moment().format("DD MMM YYYY")}</div> */}
             </div>
             <div className="card-head-right">
             {/* <SearchFilter
            data={searchString}
            setSearchString={setSearchString}
            placeholder={"Search Employee By Name"}
          /> */}
       

         
          <SelectPickerInput
            sidelabel="Select Year"
            defaultselect={currentYear}
            data={dropdownData}
            value={selectedYear}
            valueKey={"value"}
            className="w-100" 
            onChange={handleYearChange}
          />
          </div>
        </div>
         
 
 

    <div className="card">
      <Table
        columns={columns}
        Data={filteredData}
        btn_name="view"
        LeaveTableHeight="LeaveTableHeight"
        custom_btn="custom_btn"
        custom_btn2="custom_btn2"
      />

    </div>


        {
                  <CommonModal open={open} onClose={() => setOpen(false)} size={view ? "sm" : "xs"} title="Leave Details">
        {view ? <LeaveView Data={modalContent}  fetchapi={fetchapi} showButton={true} /> : null}
        {deleteModal ? <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} /> : null}
      </CommonModal>
      }

      </div>
     
      {/* <CoreLoader show={loading} /> */}
    </>
  );
};

export default EmployeeLeaveRequest;
