const endpoints = {
  LOGIN: "login",
  SIGNUP: "register",
  FORGOTPASSWORD: "forgotpassword",
  RESETPASSWORD: "resetpassword",
  ADDEMPLOYEE: "addemployee",
  ADDDEPARTMENTANDMAIL: "adddepartment",
  ACCOUNTDETAILS: "bankdetail",
  UPDATEEMPLOYEE: "updateemployee",
  ATTENDANCE: "attendance",
  ADDEMPLOYEEADDRESS: "addempaddress",
  ADDEMPLOYEEDOCS: "addempdocument",
  EMPLOYEELIST: "employeelist",
  EMPLOYEELISTFILTER: (params) => `employeelist?month=${params.month}&year=${params.year}`,
  EMPLOYEESEARCHLIST: (params) => `employeelist?search=${params}`,
  VERIFYOTP: "verifyotp",
  RESEND: "resendotp",
  CREATELEAVE: "createleave",
  LEAVELIST: "leavelist",
  LEAVESTATUS: "leaveapproved",
  ALLCOUNTRY: "country",
  CTCDETAILS: "ctcdetails",
  ROLES: "roles",
  USERINFO: "userinfo",
  ASSETLIST: "assetlist",
  BULKASSET: "bulkasset",
  ASSETASSIGN: "assectassign",
  ASSETASSIGNLIST: "assetassignlist",
  REVOKEASSET: "revokeasset",
  UPDATEASSETS: "updateasset",
  VERIFYASSETS: "verify",
  INVANTORY: "invantory",
  EMPLOYEECOUNT: "employeecount",
  EMPLOYEEDOCS: "/create/document",
  HRDASHOBARD: "/hr/dashboard/allempdata",
  EMPLOYEEBIRTHDAY:"current_day_birthdays",
  EMPLOYEEBIRTHMONTH:"current_month_birthdays",
  EMPLOYEEANIVERSARY: "emp_work_anniversary",
  HRDEPARTMENT: "/hr/dashboard/department/count",
  CATEGORYDEPARTMENT: (params) => `/hr/dashboard/department/count?month=${params.month}&year=${params.year}`,
  HRDASHBOARDYEARLYDATA: "/hr/dashboard/yearly/data ",
  HRONBAOARDING: "/hr/dashboard/employee/status",
  HREMPLOYEESTATUS: "/hr/dashboard/employee/attendace/status",
  HRDASHBOARDASSEST: "/hr/dashboard/asset/status",
  TOTALBOARDASSEST: "/asset_count ",
  CREATEDOCUMENT: (param) => `/employeedetails${param}`,
  ONLEAVEEMPLOYEE: "onleaveemployee",
  HOLIDAYLIST: `/holiday/list`,
  ADDBULKHOLIDAY: "addbulkholiday",
  HOLIDAYPENDING: "/getholiday",
  HOLIDAYAPPROVED: "/holiday/approved",
  CREATEHOLIDAY: "createholiday",
  ATTENDANCECALENDERHOLIDAYLIST: "holidaylist",
  GETDROPDOWNTYPES: (type) => `metadata?type=${type}`,
  UPDATEHOLIDAY: "updateholiday",
  GETASSET: (id) => `getasset/${id}`,
  ALLCORRESPONDINGSTATE: (name) => `statename/${name}`,
  ALLSTATESBYID: (ID) => `state/${ID}`,
  ALLCORRESPONDINGCITY: (id) => `city/${id}`,
  CANDIDATEDETAILS: (id) => `/candidateDetails/${id}`,
  PAYROLLVIEW: (id) => `payroll/list/${id}`,
  // PAYROLLVIEW: (id) => "/payroll/list/65aa1d2de88be5f81d11cb7b",
  CANDIDATEADDRESSDETAILS: (id) => `/candidateAddressDetails/${id}`,
  CANDIDATEDOCUMENTS: (id) => `/candidatedocuments/${id}`,
  CANDIDATEALLDATA: (id) => `/candidateAllData/${id}`,
  ADDASSETS: "addasset",
  ACTIVEEMPLOYEES: (status) => `/employeelist${status}`,
  GENERATEDOCUMENT: (data) => `/view/documnet/${data.id}/${data.document}`,
  APPROVEDDOCUMENT: "/approved/list",
  MANAGEFUNDTOTALEMPLOYEE: "managefund/dashboard/staticData",
  MANAGEFUNDPAYROLLLIST: "/payroll/list",
  PERSONALINFORMATIONATTENDANCE: "/personal/info",
  ATTENDANCEDASHBOARDINOUT: "/checkin/checkout",
  ATTENDANCEOVERALL: "/list/employee",
  ATTENDANCEWEEKLY: "/week_attendance",
  BREAKTIME: "/break_time",
  EMPLOYEEWORK: "/working_time_list/week",
  EMPLOYEEDATATABLE: "/emp/data",
  EMPLOYEESALARYSTATUS: "/salary/details/byemployee",
  ATTENDANCELEAVEAPPROVED: "/today/leave/approved/list",
  ATTENDANCEGRAPHLIST: "/graph/year/list",
  OVERALLLEAVELIST: "/graph/overall/leave",
  PAYROLLCOSTEMPLOYEECOST: "/payroll/page/payrollcost/employeecost",
  PAYROLLTOTALPAYDAYS: "/total/payDays",
  CHANGESTATUS: "change/status",
  DOWNLOADDOCUMENT: (data) => `/generate/documnet/${data.id}/${data.document}`,
  APPROVEDDOCUMENT: "/approved/documnet",
  APPROVEDLIST: "document/list",
  CREATEOFFBOARDING: "/offboarding/create",
  OFFBOARDINGPENDINGLIST: (id) => `/offboarding/list?offboarding_status=${id}`,
  OFFBOARDINGVIEW: "/offboarding/view",
  OFFBOARDINGSTATUS: "/offboarding/status",
  OFFBOARDINGCOMPLETELIST: "/offboarding/list",
  CREATEADVANCESALARY: "/advance/salary/create",
  ADVANCESALARYLIST: "/advance/salary/list",
  ADVANCESALARYVIEW: (id) => `/advance/salary/${id}`,
  ADVANCESALARYSTATUS: "/advance/salary/status",
  SALARYLIST: "salary/list",
  UPDATESALARY: `/update/salary`,
  APPROVEDSALARY: "/approved/salary",
  PAYSALARY: "pay/salary",
  SALARYSLIP: (id) => `salaryslip?id=${id}`,
  TRANSIONTIONLIST: (id) => `/trasiontion/history/list?empid=${id}`,
  DAYATTENDANCELIST: "oneday_attendance",
  UPDATEATTENDANCE: "update/attendance",
  GETDESIGNATION: (type) => `/department/${type}`,
  EMPLOYEELEAVELIST: "/leavelist",
  EMPLOYEELEAVECOUNT: "/leave_count",
  EMPLOYEEATTENDANCE:"/working_time_list",
  EMPLOYEEPAYROLL:"/emp/salary/list",
  EMPLOYEEADVANCE:"/advance/salary/list",
  ATTENDANCECARD:"/working_hours_count_list",






  
};

export { endpoints };
