import { Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function PayrollForm({
  data,
 
}) {
  const [loading, setLoading] = useState(false);
  const [advansSalaryData, setAdvansSalaryData] = useState([]);
  const navigate = useNavigate();
  // const BASE_URL = process.env.REACT_APP_BASE_URL;



  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12 ps-5 pt-4">
                {/* <img src={`${BASE_URL}/${data.image}`} alt="Asset" width="300" /> */}
              </div>
              <div className="col-lg-12 pt-2">
                <ul>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Employee Name</span>
                    <span className="col-lg-6 pt-3 text-dark-emphasis">{data?.empname || "NA"}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Employee Id</span>
                    <span className="col-lg-6 pt-3 text-dark-emphasis">{data?.employeeID || "NA"}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Designation</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data?.designation}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Department</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data?.department}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Pancard_No</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data?.pancard_No}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Gender</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data?.gender}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Account_no</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data?.account_no}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Pay Mode</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data?.account_no}</span>
                  </li>
                  <li className="row">
                    <span className="col-lg-6 pt-3">Status</span>
                    <span className="col-lg-6 text-dark-emphasis pt-3">{data?.salary_status}</span>

                    {/* <span className="col-lg-6 pt-3" style={{ color: paymentStatus === "unpaid" ? "red" : "green" }}>
                      {paymentStatus}
                    </span> */}
                  </li>
                  <div className="row">
              <div className="col-lg-6 pt-3">UAN</div>
              {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{data.presentDay || "NA"}</div> */}
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">PF No.</div>
              {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{data.baseSalary || "NA"}</div> */}
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">Present Day</div>
              {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{data.presentDay || "NA"}</div> */}
          
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">Gross Salary(monthly)</div>
              {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{data.totalDeductions || "NA"}</div> */}
            </div>
            <div className="row">
              <div className="col-lg-6 pt-3">Joining Date</div>
              {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{data.date_of_joining || "NA"}</div> */}
            </div>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
           
            <div className="row">
              <div className="col-lg-6 pt-3">Total Leave</div>
              <div className="col-lg-6 pt-3 text-dark-emphasis">{data.totalLeave || "NA"}</div>
            </div>
            <div className="row">
              <div className="col-lg-12 pt-3">
                <h5>Paid Leave:</h5>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Advance Salary</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{data.advanceSalary || "NA"}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Basic Salary</div>
                  {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{data.hra || "NA"}</div> */}
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">HRA</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{data.hra || "NA"}</div>

                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Transport Allowance</div>
                  {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalEmiCount || "NA"}</div> */}
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Bonus</div>
                  {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalEmiCount || "NA"}</div> */}
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Total Earning</div>
                  {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalEmiCount || "NA"}</div> */}
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Net Salary</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{data.totalnetsalary || "NA"}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">TDS</div>
                  {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalEmiCount || "NA"}</div> */}
                </div>
              </div>
              <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Leave Deduction</div>
                  {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalEmiCount || "NA"}</div> */}
                </div>
            </div>
            <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Miscellaneous</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{data.miscellaneous || "NA"}</div>
                </div>
          </div>
          <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Advance Salary</div>
                  {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalEmiCount || "NA"}</div> */}
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">PF</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{data .pf || "NA"}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">ESI</div>
                  <div className="col-lg-6 pt-3 text-dark-emphasis">{data.esi || "NA"}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pt-3 text-dark-emphasis">Total Deductions</div>
                  {/* <div className="col-lg-6 pt-3 text-dark-emphasis">{advansSalaryData.totalEmiCount || "NA"}</div> */}
                </div>
          {/* <div className="col-lg-10 pb-2 pt-3">
            <div className="d-flex justify-content-end gap-2">
              {showPaySlipButton && paymentStatus !== "unpaid" && <Button label="Pay Slip" onClick={getSalarySlip} />}
              {showPayHoldButton && paymentStatus === "paid" && (
                <Button label="Hold" onClick={() => handlePayment("hold")} disabled={loading} />
              )}
              {showPayHoldButton && paymentStatus === "paid" && (
                <Button
                  label="Edit"
                  onClick={() => navigate("/hrtools/manage-fund/edit-salary/form", { state: { data } })}
                  disabled={loading}
                />
              )}
            </div>
          </div> */}
        </div>
      </div>
      <CoreLoader show={loading} disableLines />
    </div>
  );
}
