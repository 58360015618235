// import React, { useEffect, useState } from "react";
// import { Drawer } from "rsuite";
// import "../../assets/style/EmployeeProfile/ProfileDrawer.css";
// import ProfileAccordion from "./ProfileAccordion";
// import { Button, CoreLoader } from "common/components";
// import apiManager from "networking/baseAPIManager";
// import moment from "moment";
// import "../../assets/style/profileEditicon.css";
// import userImage from "../../assets/images/UserDefultImage.png";
// import { useDispatch } from "react-redux";
// import { triggerRefresh } from "redux/slice";

// const ProfileDrawer = ({ setOpen, data, open }) => {
//   const dispatch = useDispatch();

//   const [candidateData, setCandidateData] = useState({});
//   const [fileData, setFileData] = useState(null);
//   const [loader, setLoader] = useState(false);
//   const [errorMessage, setErrorMessage] = useState(""); // New state for error message

//   const BASE_URL = process.env.REACT_APP_BASE_URL;
//   const fullName = `${data.firstname} ${data.middlename} ${data.lastname}`;
//   const isOnLeave = data.start_date && data.end_date;
//   const empid = data?._id;

//   const dateOfJoin = moment(candidateData.createdAt).format("DD MMM YYYY");
//   const startDate = moment(data.start_date).format("DD MMM YYYY");
//   const endDate = moment(data.end_date).format("DD MMM YYYY");

//   useEffect(() => {
//     if (empid) {
//       getAllDataCandidate();
//     }
//   }, [empid]);

//   useEffect(() => {
//     if (fileData) {
//       changeProfilePicture();
//     }
//   }, [fileData]);

//   const getAllDataCandidate = () => {
//     setLoader(true);
//     apiManager
//       .candidateAllData(empid)
//       .then((res) => {
//         setCandidateData(res?.data);
//       })
//       .catch((err) => {
//         console.log(err, "API Error all Data");
//       })
//       .finally(() => {
//         setLoader(false);
//       });
//   };

//   const handleEditClick = () => {
//     const fileInput = document.getElementById("fileInput");
//     if (fileInput) {
//       fileInput.click();
//     }
//   };

//   const changeProfilePicture = () => {
//     if (fileData) {
//       let formData = new FormData();
//       formData.append("empid", empid);
//       formData.append("image", fileData);
//       apiManager
//         .updateEmployee(formData)
//         .then((response) => {
//           console.log(response, "response");
//           getAllDataCandidate();
//           setErrorMessage(""); // Clear error message on success
//           dispatch(triggerRefresh(true));
//         })
//         .catch((error) => {
//           console.log(error, "error");
//           setErrorMessage("Failed to update profile picture. Please try again."); // Set error message
//         });
//     }
//   };

//   const handleClose = () => {
//     setOpen(false);
//     dispatch(triggerRefresh(false));
//   };

//   return (
//     <>
//       <Drawer open={open} onClose={handleClose} size="sm">
//         <Drawer.Header className="drawer-Header">
//           <Drawer.Title className="d-flex justify-content-center">
//             <b>Employee Profile</b>
//           </Drawer.Title>
//         </Drawer.Header>
//         <Drawer.Body>
//           <div className="container-fluid d-flex flex-column h-100 mb-2">
//             <div className="px-1">
//               <div className="row mt-3">
//                 <div className="col-lg-6 justify-content-center">
//                   <img
//                     className="drawerUserImage"
//                     src={candidateData?.image ? `${BASE_URL}/${candidateData?.image}` : userImage}
//                   />
//                   <div className="profileEdit" onClick={handleEditClick}>
//                     <div>
//                       <input
//                         id="fileInput"
//                         type="file"
//                         style={{ display: "none" }}
//                         onChange={(e) => {
//                           setFileData(e.target.files[0]);
//                         }}
//                       />
//                       <span className="material-symbols-outlined pointer p-2 bg-primary-subtle border border-primary-subtle rounded-circle">
//                         edit
//                       </span>
//                     </div>
//                   </div>

//                   {/* Error message below the image */}
//                   {errorMessage && (
//                     <p className="text-danger mt-2" style={{ fontSize: "14px", textAlign: "center" }}>
//                       {errorMessage}
//                     </p>
//                   )}
//                 </div>
//                 <div className="col-lg-6">
//                   <h4>{fullName}</h4>
//                   <p>
//                     Employee Department:{" "}
//                     <span className="text-primary">{candidateData.department ? candidateData.department : "N/A"}</span>
//                   </p>
//                   <p>
//                     Employee ID:{" "}
//                     <span className="text-primary">{candidateData.employeeID ? candidateData.employeeID : "N/A"}</span>
//                   </p>
//                   <p className="text-primary">Date of Joining: {dateOfJoin}</p>
//                 </div>
//               </div>
//               <div className="row mt-4">
//                 <div className="col-lg-12">
//                   <ul className="drawerUserDetail ps-0">
//                     <li className="row">
//                       <span className="col-lg-4">Phone</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">{candidateData.mobile_number}</span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-4">Email</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">{candidateData.company_email}</span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-4">Birthday</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">
//                         {moment(candidateData.documentDob).format("DD MMM YYYY")}
//                       </span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-4">Office Location</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">
//                         {candidateData.worklocation &&
//                         candidateData.worklocation.cityname &&
//                         candidateData.worklocation.countryname
//                           ? `${candidateData.worklocation.cityname} (${candidateData.worklocation.countryname})`
//                           : "N/A"}
//                       </span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-4">Gender</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">{candidateData.gender}</span>
//                     </li>
//                     {isOnLeave && (
//                       <li className="row">
//                         <span className="col-lg-4">On Leave</span>
//                         <span className="col-lg-2">:</span>
//                         <span className="col-lg-4 text-dark-emphasis">
//                           {startDate} To {endDate}
//                         </span>
//                       </li>
//                     )}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             <div className="mt-auto pb-4">
//               <ProfileAccordion data={candidateData} />
//             </div>
//             <div className="pb-2 d-flex justify-content-center">
//               <Button label="Close" onClick={handleClose} />
//             </div>
//           </div>
//         </Drawer.Body>
//       </Drawer>
//       <CoreLoader show={loader} disableLines />
//     </>
//   );
// };

// export default ProfileDrawer;

import React, { useEffect, useState } from "react";
import { Drawer } from "rsuite";
import "../../assets/style/EmployeeProfile/ProfileDrawer.css";
import ProfileAccordion from "./ProfileAccordion";
import { Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import moment from "moment";
import "../../assets/style/profileEditicon.css";
import userImage from "../../assets/images/UserDefultImage.png";
import { useDispatch } from "react-redux";
import { triggerRefresh } from "redux/slice";
///profile detais pages import //
import profileimg from "../../assets/images/profileimg.png";
import { PiBagSimple } from "react-icons/pi";
import { IoMailOutline } from "react-icons/io5";
import { IoDownloadSharp } from "react-icons/io5";
import { SubHeader } from "UI_Components";
import { useLocation } from "react-router-dom";
const ProfileDrawer = ({ setOpen, open }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const [candidateData, setCandidateData] = useState({});
  const [fileData, setFileData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const data = location?.state?.data
  const empid = data?._id;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const fullName = `${data?.firstname} ${data?.middlename} ${data?.lastname}`;
  const isOnLeave = data?.start_date && data?.end_date;
  const dateOfJoin = moment(candidateData?.createdAt).format("DD MMM YYYY");
  const startDate = moment(data?.start_date).format("DD MMM YYYY");
  const endDate = moment(data?.end_date).format("DD MMM YYYY");
  const Add = candidateData;
  console.log(location, 'location')
  let Address = "";
  if (Add)
    Address =
      (Add.line1 || "") +
      " " +
      (Add.line2 || "") +
      " " +
      (Add.line3 || "") +
      " " +
      (Add.cityname || "") +
      " ," +
      (Add.countryname || "");

  let checkAddress = String(Address).replaceAll(" ", "");

  if (checkAddress === "," || checkAddress === "undefined") {
    Address = "null";
  }

  let bankdetails = candidateData?.bankdetails || {};
  let femilyMemberFullName =
    (candidateData?.family_member_first_name !== undefined ? candidateData?.family_member_first_name : "NA") +
    " " +
    (candidateData?.family_member_last_name !== undefined ? candidateData?.family_member_last_name : "NA");
  let documents = candidateData?.documents || [{}];
  documents = documents[0];
  
  useEffect(() => {
    if (empid) {
      getAllDataCandidate();
    }
  }, [empid]);

  useEffect(() => {
    if (fileData) {
      validateAndUploadFile();
    }
  }, [fileData]);

  const getAllDataCandidate = () => {
    setLoader(true);
    apiManager
      .candidateAllData(empid)
      .then((res) => {
        setCandidateData(res?.data);
      })
      .catch((err) => {
        console.log(err, "API Error all Data");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleEditClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const validateAndUploadFile = () => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!allowedTypes.includes(fileData.type)) {
      setErrorMessage("Only JPEG, JPG, or PNG files are allowed.");
      return;
    }

    if (fileData.size > maxFileSize) {
      setErrorMessage("File size must be less than 2MB.");
      return;
    }

    // If validation passes, proceed to upload
    changeProfilePicture();
  };

  const changeProfilePicture = () => {
    if (fileData) {
      let formData = new FormData();
      formData.append("empid", empid);
      formData.append("image", fileData);

      apiManager
        .updateEmployee(formData)
        .then((response) => {
          getAllDataCandidate();
          setErrorMessage(""); // Clear error message on success
          dispatch(triggerRefresh(true));
        })
        .catch((error) => {
          console.log(error, "error");
          setErrorMessage("Failed to update profile picture. Please try again.");
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(triggerRefresh(false));
  };

  return (
    <>

      <div className="container-fluid">
        <div className="mt-4 mb-4">
          <div className="card-title">Employees Details</div>
        </div>
        <div className="row Emp-Det-Row">
          <div className="col-4">
            <div className="Emp-Det-left">
              <div className="card">
                <div className="Emp-top-info clearfix">
                  <div className="Emp-profile-img">
                    <img src={candidateData?.image ? `${BASE_URL}/${candidateData?.image}` : userImage} alt="" />
                  </div>
                  <div className="Emp-profile-text">
                    <h5>{fullName}</h5>
                    <p><PiBagSimple /> {candidateData?.designation ? candidateData?.designation : "N/A"} </p>
                    <p className="m-0"><IoMailOutline /> {candidateData.email}</p>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-title">Personal Details</div>
                <div className="Emp-Det-List">
                  <ul>
                    <li>
                      <span>Documental DOB</span>
                      <p> {moment(candidateData.documentDob).format("DD MMM YYYY")}</p>
                    </li>
                    <li>
                      <span>Original DOB</span>
                      <p>{candidateData.originalDob}</p>
                    </li>
                    <li>
                      <span>Gender</span>
                      <p>{candidateData.gender}</p>
                    </li>
                    <li>
                      <span>Marital Status</span>
                      <p>{candidateData.relationship}</p>
                    </li>
                    <li>
                      <span>Mobile No.</span>
                      <p>{candidateData.mobile_number}</p>
                    </li>
                    <li>
                      <span>Emergency Contact No.</span>
                      <p>{candidateData.emergency_number}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-title">Professional Details</div>
                <div className="Emp-Det-List">
                  <ul>
                    <li>
                      <span>Employee ID</span>
                      <p>{candidateData.employeeID ? candidateData.employeeID : "N/A"}</p>
                    </li>
                    <li>
                      <span>Mail Id</span>
                      <p>{candidateData.company_email}</p>
                    </li>
                    <li>
                      <span>Department</span>
                      <p>{candidateData.department ? candidateData.department : "N/A"} </p>
                    </li>
                    <li>
                      <span>Designation</span>
                      <p>{candidateData?.designation  ? candidateData?.designation  : "N/A"} </p>
                    </li>
                    <li>
                      <span>Joining Date</span>
                      <p> {dateOfJoin} </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="Emp-Det-left">
              <div className="accordion" id="EmployeesAccordionRobots">
                <div className="accordion-item">
                  <div id="regularHeadingFirst" className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseFirst" aria-expanded="true" aria-controls="regularCollapseFirst">
                      Education
                    </button>
                  </div>
                  <div id="regularCollapseFirst" className="accordion-collapse collapse show" aria-labelledby="regularHeadingFirst" data-bs-parent="#EmployeesAccordionRobots">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Secondary Passing </label>
                            <div className="Emp-form-info">

                              <p> {documents?.secondary_passing ? documents?.secondary_passing : "NA"} % </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Senior Passing </label>
                            <div className="Emp-form-info">
                              <p> {documents?.senior_passing ? documents?.senior_passing : "NA"} %</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Bachelor’s Passing  </label>
                            <div className="Emp-form-info">
                              <p>  {documents?.bachelor_passing ? documents?.bachelor_passing : "NA"} % </p>
                              {/* <span>
                                <IoDownloadSharp />
                              </span> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">{documents?.extra?.length ? "Skills" : ""} </label>
                            <div className="Emp-form-info">
                              {documents?.extra &&
                                documents?.extra?.map((extraDetail, index) => {
                                  return (
                                    <>
                                      <li className="row">
                                        <span className="col-lg-5"> {extraDetail?.degreeField}</span>
                                        <span className="col-lg-1"> :</span>
                                        <span className="col-lg-6 text-dark-emphasis">
                                          {extraDetail?.degreepercent ? extraDetail?.degreepercent : "NA"}%
                                        </span>
                                      </li>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-header" id="regularHeadingSecond">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseSecond" aria-expanded="false" aria-controls="regularCollapseSecond">
                      Experience
                    </button>
                  </div>
                  <div id="regularCollapseSecond" className="accordion-collapse collapse" aria-labelledby="regularHeadingSecond" data-bs-parent="#EmployeesAccordionRobots">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Company Name</label>
                            <div className="Emp-form-info">
                              <p>   {documents?.companyname ? documents?.companyname : "NA"} </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables"> Start Date </label>
                            <div className="Emp-form-info">
                              <p>    {documents?.start_date ? documents?.start_date : "NA"} </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">End Date</label>
                            <div className="Emp-form-info">
                              <p>    {documents?.end_date ? documents?.end_date : "NA"}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-header" id="regularHeadingThrid">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseThrid" aria-expanded="false" aria-controls="regularCollapseThrid">
                      Bank Details
                    </button>
                  </div>
                  <div id="regularCollapseThrid" className="accordion-collapse collapse" aria-labelledby="regularHeadingThrid" data-bs-parent="#EmployeesAccordionRobots">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Bank Name </label>
                            <div className="Emp-form-info">
                              <p>   {bankdetails.bank_name ? bankdetails.bank_name : "NA"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Bank Account No. </label>
                            <div className="Emp-form-info">
                              <p> {bankdetails.account_no ? bankdetails.account_no : "NA"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">IFSC Code</label>
                            <div className="Emp-form-info">
                              <p>   {bankdetails.ifsc_code ? bankdetails.ifsc_code : "NA"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">PAN No. </label>
                            <div className="Emp-form-info">
                              <p>  {candidateData?.pancard_no ? candidateData?.pancard_no : "NA"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Aadhaar No. </label>
                            <div className="Emp-form-info">
                              <p>   {candidateData?.aadharcard_no ? candidateData?.aadharcard_no : "NA"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CoreLoader show={loader} disableLines />
    </>
  );
};

export default ProfileDrawer;
